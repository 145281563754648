<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banner from "@/components/widgets/banner";
import Stat from "@/components/widgets/stat";
import { VMoney } from 'v-money';
import TradingViewTv from "@/components/widgets/chart-tradingview-tv";
import TradingViewTicker from '@/components/widgets/chart-tradingview-ticker.vue';
import Rss from '@/components/widgets/rss.vue';

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Plan': 'Plano',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Plan': 'Plano',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banner,
    Stat,
    TradingViewTicker,
    TradingViewTv,
    Rss
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        profit: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: [],

      statData: null,
      wallets: null,

      notifications: true,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00'
      },

      profitability: {
        loading: true,
        type: 'variable',
        year: '2022',
        investment: 'R$ 0,00',
        list: [],
        accumulated: '0%',
        average: '0%',
        bonus: '0.00',
      },

      ibovespa: {
        list: [],
        accumulated: '0%',
        average: '0%',
        bonus: '0.00'
      },

      simulate: {
        type: 'variable',
        year: '2022',
        investment: 'R$ 0,00',
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
    };
  },
  directives: {
    money: VMoney
  },
  methods: {
    getMonthly() {
      api
        .get('report/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalProfit() {
      api
        .get('report/profit/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.profit = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.bonus = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = response.data.balance
            this.getStatData();
          }
        })
    },
    getStatData() {
      if (this.account.user.package.access == 'limited') {
        this.statData = [
          {
            type: 'center',
            icon: 'profit',
            title: 'Rendimentos',
            value: this.total.profit,
          },
          {
            type: 'center',
            icon: 'balance',
            title: 'Current Balance',
            value: this.total.balance,
          }
        ]
      } else {
        this.statData = [
          {
            type: 'center',
            icon: 'profit',
            title: 'Rendimentos',
            value: this.total.profit,
          },
          {
            type: 'center',
            icon: 'balance',
            title: 'Current Balance',
            value: this.total.balance,
          }
        ]
      }
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api
          .get('user/notifications')
          .then(response => {
            if (response.data.status==='success') {
              if (response.data.devices == 'active') {
                this.notifications = true
              } else {
                this.notifications = false
              }
            } else {
              this.notifications = false
            }
          });
      }
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status=='success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
    getProfitability() {
      this.profitability.loading = true
      this.profitability.list = []
      this.profitability.accumulated = 0
      this.profitability.average = 0

      api
        .get('contract/profitability?type=' + this.profitability.type + '&year=' + this.profitability.year + '&investment=' + this.profitability.investment)
        .then((response) => {
          if (response.data.status == 'success') {
            this.profitability.list = response.data.contract.list
            this.profitability.accumulated = response.data.contract.accumulated
            this.profitability.average = response.data.contract.average
            this.profitability.bonus = response.data.contract.bonus

            this.ibovespa.list = response.data.ibovespa.list
            this.ibovespa.accumulated = response.data.ibovespa.accumulated
            this.ibovespa.average = response.data.ibovespa.average
            this.ibovespa.bonus = response.data.ibovespa.bonus
          }

          this.profitability.loading = false
        })
    },
    simulateProfit() {
      if ( this.profitability.type != this.simulate.type
        || this.profitability.year != this.simulate.year
        || this.profitability.investment != this.simulate.investment) {
        this.profitability.type = this.simulate.type
        this.profitability.year = this.simulate.year
        this.profitability.investment = this.simulate.investment
        this.getProfitability()
      }
    }
  },
  mounted() {
    this.getTotalProfit()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getProfitability()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="!notifications" to="/account/notifications" class="d-none text-dark">
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>Fique informado de todas as atualizações do seu escritório virtual, ative suas notificações Web Push!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banner></Banner>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" :class="account.user.package.access == 'limited' ? 'col-md-4' : 'col-md-6 col-lg-6 col-xl-3'">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-8 p-0">
        <div class="card">
          <div class="card-body p-4">
            <TradingViewTicker class="mb-3"></TradingViewTicker>
            <TradingViewTv></TradingViewTv>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 p-0">
        <Rss></Rss>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #090a09;
}
.contract-code {
  line-height: 14px;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #BEBFC1;
}
.table-month th {
  background: #D2D3D4;
}
</style>